import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'


// bootstrap
// import { bootstrap } from 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import bootstrap from 'bootstrap/dist/js/bootstrap.min'


// v-smooth-scroll
import VueSmoothScroll from 'v-smooth-scroll'

// Import App Custom Styles
import './assets/sass/style.scss'



// createApp(App).use(router).use(bootstrap).mount('#app')
const app = createApp(App)
  .use(router)
  .use(bootstrap)
  .use(VueSmoothScroll, {
    duration: 100,
    updateHistory: false,
  });

router.isReady().then(() => {
  app.mount('#app');
});
