<template ref="agreement">
  <div class="tBlk">

<!-- Agreement text -->
    <span class="p10h bB">この規約は、ユーザーが、scratchart.app が提供する「SCAPP -スクラッチアートのアプリ- スクアプ」サービス（以下「本サービス」）をご利用頂く際の取扱いについて定めるものです。<br>
    本規約に同意された上で、本サービスをご利用ください。</span><br>

    <br>


    <hr>


    <br><br>

    <h4 class="p10h bB text-center">第１条（定義）</h4>
    本規約上で使用する用語の定義は、次に掲げるとおりとします。<br>
    <br>
    （１）本サービス：
    scratchart.appが運営するサービス及び関連するサービス<br>
    （２）本サイト：
    本サービスのコンテンツが掲載されたウェブサイト<br>
    （３）本コンテンツ：
    本サービス上で提供される文字、音、静止画、動画、ソフトウェアプログラム、コード等の総称（投稿情報を含む）<br>
    （４）利用者：
    本サービスを利用する全ての方<br>
    （５）登録情報：
    登録利用者が本サイトにて登録した情報の総称（SNS連動等での投稿情報は除く）<br>
    （６）知的財産：
    発明、考案、植物の新品種、意匠、著作物その他の人間の創造的活動により生み出されるもの（発見または解明がされた自然の法則または現象であって、産業上の利用可能性があるものを含む）、商標、商号その他事業活動に用いられる商品または役務を表示するもの及び営業秘密その他の事業活動に有用な技術上または営業上の情報<br>
    （７）知的財産権：
    特許権、実用新案権、育成者権、意匠権、著作権、商標権その他の知的財産に関して法令により定められた権利または法律上保護される利益に係る権利<br>

    <br><br>

    <h4 class="p10h bB text-center">第２条（本規約への同意）</h4>
    （１）利用者は、本利用規約に同意頂いた上で、本サービスを利用できるものとします。<br>
    （２）利用者が、本サービスをスマートフォンその他の情報端末にダウンロードし、本規約への同意手続を行った時点で、利用者とscratchart.appとの間で、本規約の諸規定に従った利用契約が成立するものとします。<br>
    （３）利用者が未成年者である場合には、親権者その他の法定代理人の同意を得たうえで、本サービスをご利用ください。<br>
    （４）未成年者の利用者が、法定代理人の同意がないにもかかわらず同意があると偽りまたは年齢について成年と偽って本サービスを利用した場合、その他行為能力者であることを信じさせるために詐術を用いた場合、本サービスに関する一切の法律行為を取り消すことは出来ません。<br>
    （５）本規約の同意時に未成年であった利用者が成年に達した後に本サービスを利用した場合、当該利用者は本サービスに関する一切の法律行為を追認したものとみなされます。

    <br><br>

    <h4 class="p10h bB text-center">第３条（規約の変更）</h4>
    （１）scratchart.appは、利用者の承諾を得ることなく、いつでも、本規約の内容を改定することができるものとし、利用者はこれを異議なく承諾するものとします。<br>
    （２）scratchart.appは、本規約を改定するときは、その内容についてscratchart.app所定の方法により利用者に通知します。<br>
    （３）前本規約の改定の効力は、scratchart.appが前項により通知を行った時点から生じるものとします。<br>
    （４）利用者は、本規約変更後、本サービスを利用した時点で、変更後の本利用規約に異議なく同意したものとみなされます。

    <br><br>

    <h4 class="p10h bB text-center">第４条（個人情報等の取り扱い）</h4>
    本サービスはお名前・メールアドレス等の個人情報登録の必要なくご利用頂けます。<br>
    なお本サービスに限らず、個人情報及び利用者情報については、scratchart.appが別途定める「プライバシーポリシー」に則り、適正に取り扱うこととします。<br>
    <div class="p10h text-center">
      <router-link to="#privacy"><p class="bB fzM">≪scratchart.app｜プライバシーポリシー≫</p></router-link>
      <span class="">※ページ下部に記載</span>
    </div>

    <br><br>

    <h4 class="p10h bB text-center">第５条（禁止行為）</h4>
    本サービスの利用に際し、scratchart.appは、利用者に対し、次に掲げる行為を禁止します。scratchart.appにおいて、利用者が禁止事項に違反したと認めた場合、利用者用の一時停止、退会処分その他scratchart.appが必要と判断した措置を取ることができます。<br>
    <br>
    （１）scratchart.appまたは第三者の知的財産権を侵害する行為<br>
    （２）scratchart.appまたは第三者の名誉・信用を毀損または不当に差別もしくは誹謗中傷する行為<br>
    （３）scratchart.appまたは第三者の財産を侵害する行為、または侵害する恐れのある行為<br>
    （４）scratchart.appまたは第三者に経済的損害を与える行為<br>
    （５）scratchart.appまたは第三者に対する脅迫的な行為<br>
    （６）コンピューターウィルス、有害なプログラムの使用またはそれを誘発する行為<br>
    （７）本サービス用インフラ設備に対して過度な負担となるストレスをかける行為<br>
    （８）本サービスに関連するサーバーやシステム、セキュリティへの攻撃<br>
    （９）scratchart.app提供のインターフェース以外の方法でscratchart.appサービスにアクセスを試みる行為<br>
    （１０）利用者が本サービスを使用した上、SNS連動等で以下の情報を投稿すること<br>
    <p class="p5 fzS">①第三者の権利および財産に対して損害を与えるリスクのある情報<br>
    ② 第三者に対して有害な情報、第三者を身体的・心理的に傷つける情報<br>
    ③ 犯罪や不法行為、危険行為に属する情報およびそれらを教唆、幇助する情報<br>
    ④ 不法、有害、脅迫、虐待、人種差別、中傷、名誉棄損、侮辱、ハラスメント、扇動、不快を与えることを意図し、もしくはそのような結果を生じさせる恐れのある内容をもつ情報<br>
    ⑤ 事実に反する、または存在しないと分かっている情報<br>
    ⑥ 利用者自身がコントロール可能な権利を持たない情報<br>
    ⑦ 第三者の著作権を含む知的財産権やその他の財産権を侵害する情報、公共の利益または個人の権利を侵害する情報<br>
    ⑧ わいせつ、児童ポルノまたは児童虐待にあたる画像、文書等の情報<br>
    ⑨ 医師法などの法令に違反する情報<br>
    ⑩ その他scratchart.appが不適切と判断する情報</p>
    （１１）上記の他、scratchart.appが不適切と判断する行為

    <br><br>

    <h4 class="p10h bB text-center">第６条 （コンテンツの取り扱い）</h4>
    （１）利用者は､本サービスのコンテンツをscratchart.appの定める範囲内でのみ使用することができるものとします｡<br>
    （２）本サービスで提供される全てのコンテンツに関する権利はscratchart.appが有しており､利用者に対し､scratchart.appが有する特許権､実用新案権､ 意匠権､商標権､著作権､その他知的財産権の実施または使用許諾をするものではありません｡<br>
    （３）利用者は､scratchart.appの定める使用範囲を超えていかなる方法によっても複製､送信､譲渡（利用者同士の売買も含みます）､貸与､翻訳､翻案、無断で転載、二次使用、営利目的の使用、改変、逆アセンブル、逆コンパイル、リバースエンジニアリング等を行う事を禁止します。<br>
    （４）前項に関わらず､退会等により利用者が利用者資格を喪失した場合は､提供されたコンテンツの使用権も消滅するものとします｡<br>
    （５）利用者が、本サービスを使用して投稿およびその他の方法で送信したコンテンツ（静止画、動画、文字情報その他一切の情報）に関する著作権（著作権法第21条ないし同第28条に規定する権利を含む全ての著作権を含む。）については、利用者に帰属するものとします。<br>
    （６）利用者は本サービス内では、著作者人格権を行使しないものとします。

    <br><br>

    <h4 class="p10h bB text-center">第７条（免責）</h4>
    （１）scratchart.appは、本サービスの内容変更、中断、終了によって生じたいかなる損害についても、一切責任を負いません。<br>
    （２）scratchart.appは、利用者の本サービスの利用環境について一切関与せず、また一切の責任を負いません。<br>
    （３）scratchart.appは、本サービスが利用者の特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性を有すること、利用者による本サービスの利用が利用者に適用のある法令または業界団体の内部規則等に適合すること、および不具合が生じないことについて、何ら保証するものではありません。<br>
    （４）scratchart.appは、本サービスが全ての情報端末に対応していることを保証するものではなく、本サービスの利用に供する情報端末のＯＳのバージョンアップ等に伴い、本サービスの動作に不具合が生じる可能性があることにつき、利用者はあらかじめ了承するものとします。scratchart.appは、かかる不具合が生じた場合にscratchart.appが行うプログラムの修正等により、当該不具合が解消されることを保証するものではありません。<br>
    （５）利用者は、AppStore、GooglePlay等のサービスストアの利用規約および運用方針の変更等に伴い、本サービスの一部又は全部の利用が制限される可能性があることをあらかじめ了承するものとします。<br>
    （６）scratchart.appは、本サービスを利用したことにより直接的または間接的に利用者に発生した損害について、一切賠償責任を負いません。<br>
    （７）scratchart.appは、利用者その他の第三者に発生した機会逸失、業務の中断その他いかなる損害（間接損害や逸失利益を含みます）に対して、scratchart.appが係る損害の可能性を事前に通知されていたとしても、一切の責任を負いません。<br>
    （８）第１項乃至前項の規定は、scratchart.appに故意または重過失が存する場合又は契約書が消費者契約法上の消費者に該当する場合には適用しません。<br>
    （９）前項が適用される場合であっても、scratchart.appは、過失（重過失を除きます。）による行為によって利用者に生じた損害のうち、特別な事情から生じた損害については、一切賠償する責任を負わないものとします。<br>
    （１０）本サービスの利用に関しscratchart.appが損害賠償責任を負う場合、当該損害が発生した月に利用者から受領した利用額を限度として賠償責任を負うものとします。<br>
    （１１）利用者と他の利用者との間の紛争及びトラブルについて、scratchart.appは一切責任を負わないものとします。利用者と他の利用者でトラブルになった場合でも、両者同士の責任で解決するものとし、scratchart.appには一切の請求をしないものとします。<br>
    （１２）利用者は、本サービスの利用に関連し、他の利用者に損害を与えた場合または第三者との間に紛争を生じた場合、自己の費用と責任において、かかる損害を賠償またはかかる紛争を解決するものとし、scratchart.appには一切の迷惑や損害を与えないものとします。<br>
    （１３）利用者の行為により、第三者からscratchart.appが損害賠償等の請求をされた場合には、利用者の費用（弁護士費用）と責任で、これを解決するものとします。scratchart.appが、当該第三者に対して、損害賠償金を支払った場合には、利用者は、scratchart.appに対して当該損害賠償金を含む一切の費用（弁護士費用及び逸失利益を含む）を支払うものとします。<br>
    （１４）利用者が本サービスの利用に関連してscratchart.appに損害を与えた場合、利用者の費用と責任においてscratchart.appに対して損害を賠償（訴訟費用及び弁護士費用を含む）するものとします。

    <br><br>

    <h4 class="p10h bB text-center">第８条（広告の掲載について）</h4>
    利用者は、本サービス上にあらゆる広告が含まれる場合があること、scratchart.appまたはその提携先があらゆる広告を掲載する場合があることを理解しこれを承諾したものとみなします。<br>
    本サービス上の広告の形態や範囲は、scratchart.appによって随時変更されます。

    <br><br>

    <h4 class="p10h bB text-center">第９条（権利譲渡の禁止）</h4>
    （１）利用者は、予めscratchart.appの書面による承諾がない限り、本規約上の地位および本規約に基づく権利または義務の全部または一部を第三者に譲渡してはならないものとします。<br>
    （２）scratchart.appは、本サービスの全部または一部をscratchart.appの裁量により第三者に譲渡することができ、その場合、譲渡された権利の範囲内で利用者のアカウントを含む、本サービスに係る利用者の一切の権利が譲渡先に移転するものとします。

    <br><br>

    <h4 class="p10h bB text-center">第１０条（分離可能性）</h4>
    本規約のいずれかの条項又はその一部が、消費者契約法その他の法令等により無効又は執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効又は執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。

    <br><br>

    <h4 class="p10h bB text-center">第１１条（scratchart.appへの連絡方法）</h4>
    本サービスに関する利用者のscratchart.appへのご連絡・お問い合わせは、本サービスまたはscratchart.appが運営するwebサイト内に設置するお問い合わせフォームからの送信、またはscratchart.appが別途指定する方法により行うものとします。

    <br><br>

    <h4 class="p10h bB text-center">第１２条（準拠法、管轄裁判所）</h4>
    （１）本規約の有効性，解釈及び履行については，日本法に準拠し，日本法に従って解釈されるものとする。<br>
    （２）scratchart.appと利用者等との間での論議・訴訟その他一切の紛争については、訴額に応じて、東京簡易裁判所又は東京地方裁判所を専属的合意管轄裁判所とします｡

    <br><br>

    <p class="text-right fzS">２０１９年４月１日施行</p>

    <br><br>
<!-- Agreement text -->

  </div>
</template>



<script>

export default {
  // コンポーネントをセット
  components: {
  },
  // 各種変数をセット
  data: function(){
    return{
    }
  },
  // 汎用の関数定義
  methods: {
  },
  // vueインスタンスがマウントされたタイミングで実行する関数
  mounted: function(){
  }

};
</script>


<style lang='scss' scoped>
  hr {
    border-color: #bbb;
  }
</style>
