<template>
  <div id="wrap">

<!-- Navigation -->
<nav class="navbar navbar-expand-lg navbar-light fixed-top" id="mainNav">
  <div class="container h-100">
    <div class="navbar-brand d-flex align-items-center py-0">
      <router-link to="/" class="tWht text-decoration-none" v-if="pagePath === '/'">SCAPP - スクアプ</router-link>
      <router-link to="/en/" class="tWht text-decoration-none" v-else>SCAPP</router-link>
    </div>

    <!-- <div id="headLink" class="tWht">
      <router-link to="/" class="tWht text-decoration-none">Jp</router-link> |
      <router-link to="/en/" class="tWht text-decoration-none">En</router-link>
    </div> -->

    <nav id="headNav" ref="headNav" class="dropdown">
      <button id="headNavBtn" ref="headNavBtn" class="btn bgBlk tWht fzM dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"></button>
      <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="headNavBtn">
        <li class="dropdown-item px-3"><a href="#download" v-smooth-scroll><p class="btn p5 m5h w100p tWht bgBlk fzM">ダウンロード</p></a></li>
        <li class="dropdown-item px-3"><a href="#features" v-smooth-scroll><p class="btn p5 m5h w100p tWht bgBlk fzM">機能</p></a></li>
        <li class="dropdown-item px-3"><a href="#agreement" v-smooth-scroll><p class="btn p5 m5h w100p tWht bgBlk fzM">利用規約</p></a></li>
        <li class="dropdown-item px-3"><a href="#privacy" v-smooth-scroll><p class="btn p5 m5h w100p tWht bgBlk fzM">プライバシーポリシー</p></a></li>
        <li class="dropdown-item px-3"><a href="#mailform" v-smooth-scroll><p class="btn p5 m5h w100p tWht bgBlk fzM">お問い合わせ</p></a></li>
      </ul>
    </nav>
  </div>
</nav>
<!-- Navigation -->


<!-- mainConts of router -->
    <div class="">
  <!-- <router-view></router-view> -->
<transition appear>
  <router-view v-bind:ref="pagePath" v-bind:id="pagePath"></router-view>
</transition>
    </div>
<!-- mainConts of router -->


    <!-- footer -->
    <footer class="bgBlk tWht">
      <div class="container">
        <p>©2019 scratchart.app</p>
      </div>
    </footer>
    <!-- footer -->

<!-- back to top -->
  <figure id="bttBtn" ref="bttBtn" class="btn dBlock tWht dFlex aFlexC jFlexC btn-outline"><a href="#top" class="tWht text-decoration-none w-100 h-100 d-flex justify-content-center  align-items-center" v-smooth-scroll>TOP</a></figure>


  </div>
</template>




<script>
export default {
  name: 'App',
  components: {
    // HelloWorld
  },

    // 各種変数をセット
  data: function(){
    return {
      pagePath: '', /*vue-routerで現状のpagePathをキープしておく*/
    }
  },

  // App.vueから渡されたdata
  props: [
  ],


  // 汎用の関数定義
  methods: {
  },


  // 監視して実行する関数定義（watchと重複？？要検証。。。）
  computed: {
  },


  // vueインスタンスがマウントされたタイミングで実行する関数
  mounted: function(){
    if (this.$route.path == '/') {
        this.pagePath = '/'
    } else {
        this.pagePath  = this.$route.path.replace(/\u002f/g, '')
    }
  },


  // 変数を監視して発火させる関数
  watch: {
      // routerの動きをチェック
    '$route': function (to, from) {
      // pathをdetaに保存しておく
      if (this.$route.path == '/') {
         this.pagePath = '/'
      } else {
         this.pagePath  = this.$route.path.replace(/\u002f/g, '')
      }
      console.log({path:this.$route.path})
      console.log({pagePath:this.pagePath})
      console.log({from:from.path})
      console.log({to:to.path})
      // ページ内リンク用設定（stc属性の#を監視）
      if (to.hash.match(/^#/)) {
        console.log(to.hash)
      }
    },

  }
};
</script>


<style lang='scss' scoped>
</style>
