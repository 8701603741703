<template ref="home">
  <div class="conts tWht bgBlk">

    <section class="mv bgGrd1" id="top">
      <div class="container h-100">
        <div class="row h-100">
          <div class="col-lg-7 my-auto">
            <div class="header-content mx-auto text-center">
              <h1 class="mb-5 mAuto" style="max-width: 240px;">
                <img src="../assets/img/scap_icon.svg" class="w50p dBlock fzS" alt="SCAPP -スクラッチアートのアプリ- スクアプ｜アイコン">
                <img src="../assets/img/scapp_logo_wht.svg" class="w50p dBlock fzS pt-3" alt="SCAPP -スクラッチアートのアプリ- スクアプ｜ロゴ">
                <img src="../assets/img/scapp_kana_wht.svg" class="w40p dBlock fzS pt-2" alt="SCAPP -スクラッチアートのアプリ- スクアプ">
              </h1>
              <h1 class="mt-5 mb-4">アプリで楽しむ<br>スクラッチアート！</h1>
              <strong class="bN fzS" style="line-height:1.4;">話題の画像生成AI<br> 「<span class="bB fzL">Stable Diffusion</span>」<br> を利用した絵柄を追加！</strong>
              <p class="fzS my-4">美麗なアートをお手元でお手軽に<br>※スマートフォン・タブレット専用</p>
              <p class="fzXl mb-5 bB">- 無料 -</p>
              <div class="text-center">
                <a href="#download" class="tWht text-decoration-none w-100 h-100 d-flex justify-content-center  align-items-center" v-smooth-scroll><p class="text-center btn btn-outline btn-xl js-scroll-trigger mAuto">ダウンロード</p></a>
              </div>
            </div>
          </div>
          <div class="col-lg-5 my-auto">
            <div class="device-container">
              <div class="device-mockup iphone12pro">
                <div class="device">
                  <div class="screen">
                    <img src="../assets/img/screen1.jpg" class="img-fluid" alt="SCAPP -スクラッチアートのアプリ- スクアプ">
                  </div>
                  <div class="button">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="download text-center bgBlk" id="download">
      <div class="container">
        <div class="row">
          <div class="col-md-8 mx-auto">
            <h2 class="section-heading">ダウンロード</h2>
            <p class="py-3">Google Play Store・App Store でのダウンロードはこちらから</p>
            <hr class="">
            <div class="badges dFlex aFlexS">
              <a class="badge-link" href="https://play.google.com/store/apps/details?id=app.rhdd.scapp" target="_blank"><img src="../assets/img/google-play-badge.svg" alt="Get it on GooglePlay"></a>
              <a class="badge-link" href="https://itunes.apple.com/jp/app/id1461662384" target="_blank"><img src="../assets/img/app-store-badge.svg" alt="Download on the AppStore"></a>
            </div>
          </div>
          <div class="col-md-8 mx-auto" style="margin-top: 80px;">
            <h3 class="" style="font-size: 30px;">リリースノート</h3>
            <hr class="">
            <div class="p20 bdr5 bgWht tBlk text-left fzS">
              <ul class="p0">
                <li class="m10h pb-2 bB">【2022.10-】ver.2.0.6リリース ≪Android・iOS≫<p class="m5h bN">・新規絵柄を追加（画像生成AI「Stable Diffusion」を利用）<br>・既存の絵柄を整理<br>・細かな不具合を調整</p></li>
                <li class="m10h pb-2 bB">【2022.3-】ver.2.0.4リリース ≪Android・iOS≫<p class="m5h bN">・描画中に小指等が画面に触れた際に、誤認識する場合があった点を改善（パームリジェクション機能）</p></li>
                <li class="m10h pb-2 bB">【2022.1-】ver.2.0.2リリース ≪Android・iOS≫<p class="m5h bN">・操作画面を改善・軽微なバグを修正</p></li>
                <li class="m10h pb-2 bB">【2019.7-】ver.1.0.4リリース ≪Android・iOS≫<p class="m5h bN">・絵柄の追加＆軽微なバグを修正</p></li>
                <li class="m10h pb-2 bB">【2019.6-】ver.1.0.3リリース ≪iOS≫<p class="m5h bN">・絵柄の追加＆スクラッチアートのおすすめ情報ページを実装</p></li>
                <li class="m10h pb-2 bB">【2019.6-】ver.1.0.2リリース ≪Android≫<p class="m5h bN">・絵柄の追加＆スクラッチアートのおすすめ情報ページを実装</p></li>
                <li class="m10h pb-2 bB">【2019.5-】ver.1.0.1リリース ≪iOS≫</li>
                <li class="m10h bB">【2019.4-】ver.1.0.0リリース ≪Android≫</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="features bgGrd2" id="features">
      <div class="container">
        <div class="section-heading text-center">
          <h2 class="fzXl2">アプリならではの機能が満載</h2>
          <p class="text-center tWht">カンタン操作で思いのままにスクラッチアートを楽しめます！</p>
          <hr class="">
        </div>

        <div class="row">
          <div class="col-lg-8 my-auto">
            <div class="container-fluid">
              <div class="row">
                <div class="col-lg-12">
                  <div class="feature-item">
                    <img src="../assets/img/pen_wht.svg" class="iconImg" alt="描いたり、消したり…何回でも試せる楽しさ">
                    <h3>描いたり、消したり…何回でも試せる楽しさ</h3>
                    <p class="tGry2">自由に描いたりた消したり、前の状態に戻したり。<br>線の太さも細かく調整が可能です。<br><br>※市販のタッチペン・スタイラスを利用するとより繊細に描けます。</p>
                    <p class="tGry2 py-3 fzM">ver.2.0.2より、直観的に楽しめる様に操作方法が調整されました。</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 my-auto">
            <div class="device-container">
              <div class="device-mockup iphone12pro">
                <div class="device">
                  <div class="screen">
                    <img src="../assets/img/screen2.jpg" class="img-fluid" alt="SCAPP -スクラッチアートのアプリ- スクアプ">
                  </div>
                  <div class="button">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-8 my-auto order-lg-2">
            <div class="container-fluid">
              <div class="row">
                <div class="col-lg-12">
                  <div class="feature-item">
                    <i class="fas fa-palette"></i>
                    <h3>カラーを自由にカスタム</h3>
                    <p class="tGry2">作品毎のオリジナルカラー・スクラッチアートで人気の光沢感のあるラメカラー・意外性のあるランダムカラーの3種からカラータイプを選択、さらに色相も自由に変更できます。<br></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 my-auto">
            <div class="device-container">
              <div class="device-mockup iphone12pro">
                <div class="device">
                  <div class="screen">
                    <img src="../assets/img/screen3.jpg" class="img-fluid" alt="SCAPP -スクラッチアートのアプリ- スクアプ">
                  </div>
                  <div class="button">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-8 my-auto">
            <div class="container-fluid">
              <div class="row">
                <div class="col-lg-12">
                  <div class="feature-item">
                    <i class="fas fa-images"></i>
                    <h3>話題の画像生成AI<br>「Stable Diffusion」<br>を利用した絵柄を追加！</h3>
                    <p class="tGry2">美麗な図柄の数々と作品ギャラリー。<br>昨今のアーティストの作品を独自にスクラッチアートに落とし込んでお届けします。<br>
                    <span class="fzS">※図柄は随時追加予定です。</span><br><br>
                    完成した作品はシンプルなギャラリー形式で一覧表示して楽しめます。</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 my-auto order-lg-1">
            <div class="device-container">
              <div class="device-mockup iphone12pro">
                <div class="device">
                  <div class="screen">
                    <img src="../assets/img/screen4.jpg" class="img-fluid" alt="SCAPP -スクラッチアートのアプリ- スクアプ">
                  </div>
                  <div class="button">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-8 my-auto order-lg-2">
            <div class="container-fluid">
              <div class="row">
                <div class="col-lg-12">
                  <div class="feature-item">
                    <i class="fas fa-share-alt"></i>
                    <h3>SNSでシェア</h3>
                    <p class="tGry2">Twitter・Facebook・Instagram・LineなどのSNSで作品を共有できます。</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 my-auto">
            <div class="device-container">
              <div class="device-mockup iphone12pro">
                <div class="device">
                  <div class="screen">
                    <img src="../assets/img/screen5.jpg" class="img-fluid" alt="SCAPP -スクラッチアートのアプリ- スクアプ">
                  </div>
                  <div class="button">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>


    <section class="bgBlk" id="">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-6 pt-5" id="agreement" style="margin-bottom: 100px;">
            <h2 class="fzXl2 mt-5">利用規約</h2>
            <hr class="">
            <div class="textBox bdr5 bgWht text-left fzS scrollBox">
              <div class="scrollInner p20">
                <!-- Agreement text -->
                <agreement />
                <!-- Agreement text -->
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 pt-5" id="privacy">
            <h2 class="fzXl2 mt-5">プライバシーポリシー</h2>
            <hr class="">
            <div class="textBox bdr5 bgWht text-left fzS scrollBox">
              <div class="scrollInner p20">
                <!-- Privacy text -->
                <privacy />
                <!-- Privacy text -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>



    <section class="bgGrd3" id="mailform">
      <div class="container">
        <h2 class="fzXl2">お問い合わせ</h2>
        <hr class="">
        <div class="textBox p20 bdr5 bgWht tBlk text-left fzS">
          <div class="">
            <!-- Form text -->
            <mailform />
            <!-- Form text -->
          </div>
        </div>
      </div>
    </section>


    <section class="contact bgGrdWht" id="sns">
      <div class="container tBlk">
        <aside class="my-5 mAuto" style="max-width: 240px;">
          <img src="../assets/img/scap_icon.svg" class="w50p dBlock fzS" alt="SCAPP -スクラッチアートのアプリ- スクアプ｜アイコン">
          <img src="../assets/img/scapp_logo_blk.svg" class="w50p dBlock pt-3" alt="SCAPP -スクラッチアートのアプリ- スクアプ｜ロゴ">
          <img src="../assets/img/scapp_kana_blk.svg" class="w40p dBlock pt-2" alt="SCAPP -スクラッチアートのアプリ- スクアプ">
        </aside>

        <div class="text-center p20 my-5 bdr10 d-inline-block mx-auto bgWht">
          <h2 class="section-heading">ダウンロード</h2>
          <p class="py-3">Google Play Store・App Store でのダウンロードはこちらから</p>
          <hr class="" style="border-color: #111;">
          <div class="badges dFlex aFlexS">
            <a class="badge-link" href="https://play.google.com/store/apps/details?id=app.rhdd.scapp" target="_blank"><img src="../assets/img/google-play-badge.svg" alt="Get it on GooglePlay"></a>
            <a class="badge-link" href="https://itunes.apple.com/jp/app/id1461662384" target="_blank"><img src="../assets/img/app-store-badge.svg" alt="Download on the AppStore"></a>
          </div>
        </div>

        <p class="my-5 fzM">≪SCAPP -スクラッチアートのアプリ- スクアプ≫ をシェア！</p>
        <ul class="list-inline list-social">
          <li class="list-inline-item social-twitter px-4">
            <a href="http://twitter.com/share?url=https://scratchart.app&text=SCAPP%20-スクラッチアートのアプリ-%20スクアプ%20%23scratchartApp%20%23スクアプ%20%23スクラッチアート" target="_blank">
              <i class="fab fa-twitter"></i>
            </a>
          </li>
          <li class="list-inline-item social-facebook px-4">
            <a href="https://www.facebook.com/sharer/sharer.php?u=https://scratchart.app" target="_blank">
              <i class="fab fa-facebook-f"></i>
            </a>
          </li>
        </ul>
        <div class="mt-5 dFlex fzS">
          <a href="https://twitter.com/search?vertical=default&q=%23scratchartApp&src=typd" target="_blank" class="backClr1 fzS dBlock m5">#scratchartApp</a>
          <a href="https://twitter.com/search?vertical=default&q=%23スクアプ&src=typd" target="_blank" class="backClr1 fzS dBlock m5">#スクアプ</a>
          <a href="https://twitter.com/search?vertical=default&q=%23スクラッチアート&src=typd" target="_blank" class="backClr1 fzS dBlock m5">#スクラッチアート</a>
        </div>
      </div>
    </section>


  </div>
</template>


<script>
// 規約・プライバシーポリシー・formコンポーネントをインポート
import Agreement from '@/components/JpAgreement.vue'
import Privacy from '@/components/JpPrivacy.vue'
import Mailform from '@/components/JpMailform.vue'

export default {
  name: 'JpHome',
  components: {
    Agreement,
    Privacy,
    Mailform,
  },

    // 各種変数をセット
  data: function(){
    return {
      pagePath: '', /*vue-routerで現状のpagePathをキープしておく*/
    }
  },

  // App.vueから渡されたdata
  props: [
  ],


  // 汎用の関数定義
  methods: {
  },


  // 監視して実行する関数定義（watchと重複？？要検証。。。）
  computed: {
  },


  // vueインスタンスがマウントされたタイミングで実行する関数
  mounted: function(){
  },


  // 変数を監視して発火させる関数
  watch: {


  }
};
</script>
