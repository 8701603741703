<template ref="form">
  <div class="tBlk">
    <div>

      <!-- form -->
      <p class="text-center p20h">お気づきの点は下記よりご連絡ください。</p>
      <form id="contactMailForm" ref="contactMailForm" action="https://docs.google.com/forms/d/e/1FAIpQLSdBDUUYTl3TtX8eHXLu1v4au_BjRbLf2-WFXB-tDqUopWaZ7g/formResponse" method="POST" target="dummyIframe" v-on:submit.prevent="showConfirmModal()">
        <div class="form-group">
          <label for="customerName" class="control-label dFlex jFlexS py-2"><span class="p5 fzL bB">お名前</span><span class="badge fzXs bgRed bB p5 tWht">必須</span></label>
          <input type="text" id="customerName" v-model="customerName" name="entry.356426370" class="form-control bgGry2" placeholder="お名前" required="required">
          <p class="py-2">{{ errors.customerName }}</p>
        </div>
        <div class="form-group">
          <label for="customerMail" class="control-label dFlex jFlexS py-2"><span class="p5 fzL bB">メールアドレス</span><span class="badge fzXs bgRed bB p5 tWht">必須</span></label>
          <input type="email" id="customerMail" v-model="customerMail" name="entry.1744133175" class="form-control bgGry2" placeholder="例）example@example.com"  required="required">
          <p class="py-2">{{ errors.customerMail }}</p>
        </div>
        <div class="form-group">
          <label for="customerComment" class="control-label dFlex jFlexS py-2"><span class="p5 fzL bB">お問い合わせ内容</span><span class="badge fzXs bgRed bB p5 tWht">必須</span></label>
          <textarea id="customerComment" v-model="customerComment" name="entry.2118576518" class="form-control bgGry2" rows="" cols="" required="required" placeholder="ご意見・ご要望等をお書きください。"></textarea>
          <p class="py-2">{{ errors.customerComment }}</p>
        </div>
        <div class="form-group p30">
          <!-- <button type="submit" class="btn p10 w100p tWht bgGrn bdr10 fzL bB iconPenWht"><span class="">送信する</span></button> -->
          <!-- <button type="" class="btn p10 w100p tWht bgGrn bdr10 fzL bB iconPenWht" @click="showConfirmModal()"><span class="">送信する</span></button> -->
          <button type="button" class="btn p10 w100p tWht bgGrn bdr10 fzL bB iconPenWht" @click="showConfirmModal()" :disabled="!meta.valid"><span class="">送信する</span></button>
        </div>
      </form>
    <!-- form -->

    </div>

<!-- dummyIframe -->
<iframe name="dummyIframe" style="display:none;"></iframe>
<!-- dummyIframe -->




<!-- confirmModal -->
<div class="modal fade" id="confirmModal" ref="confirmModal" tabindex="-1" aria-labelledby="confirmModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <figure class="modalHideBtn" v-on:click="hideConfirmModal()"><i class="fas fa-times-circle tBlk"></i></figure>
        <p  v-if="formViewFrag" class="w100p tBlk"><span class="backClr1 bB fzM">送信内容の確認</span></p>
        <p v-else class="w100p tBlk"><span class="backClr1 bB fzM">送信完了！</span></p>
      </div>
      <div class="modal-body">
      <!-- 確認画面 -->
        <div v-if="formViewFrag">
          <div class="modalMain p10">
            <div class="fzM tBlk text-left">
              <p class="bB pb-3">お名前: <br><span class="bN pl-2">{{ customerName }}</span></p>
              <p class="bB pb-3">メールアドレス: <br><span class="bN pl-2">{{ customerMail }}</span></p>
              <p class="bB">お問い合わせ内容: <br><span class="bN pl-2">{{ customerComment }}</span></p>
            </div>
            <div class="dFlex aFlexSt pt-4">
              <div class="dFlex p10h">
                <button v-on:click="hideConfirmModal()" class="btn p10 w100p tWht bgGry6 bdr10 fzL bB m5">cancel</button>
                <button v-on:click="submitAfter()" class="btn p10 w100p tWht bgGrn bdr10 fzL bB m5 iconPenWht" :disabled="isSubmitting"><span class="">送信する</span></button>
              </div>
            </div>
          </div>
        </div>
      <!-- 確認画面 -->
        <div v-else>
          <p class="fzM">お問い合わせありがとうございました。<br><br>のちほど担当者よりご連絡させていただきますので、いましばらくお待ちください。</p>
          <div class="dFlex aFlexSt pt-4">
            <div class="dFlex p10h deleteBtnBox">
              <button v-on:click="hideConfirmModal()" class="btn p10 w100p tWht bgGry6 bdr10 fzL bB m5">戻る</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- confirmModal -->

  </div>
</template>



<script>

// bootstrap-module
import bootstrap from 'bootstrap/dist/js/bootstrap.min'

// vee-validate
// import { Field, Form, ErrorMessage } from 'vee-validate';
import { useField, useForm } from 'vee-validate';
// import * as yup from 'yup';
import { object, string } from 'yup';

export default {
  // コンポーネントをセット
  components: {
  },
  // 各種変数をセット
  data: function(){
    return{
      formPreparation: false, /*form入力確認フラグ*/
      formViewFrag: true, /*form画面表示切り替えラグ*/
      confirmModal: '', /*modalオブジェクト・インスタンス化はmountedで行う*/

      // 以下はsetup内での定義に変更
      // customerName: '', /*form送信用*/
      // customerMail: '', /*form送信用*/
      // customerComment: '', /*form送信用*/
    }
  },



  setup() {

  // form vaidation
    const schema = object({
      customerName: string()
        .required('必須の項目です。'),
      customerMail: string()
        .required('必須の項目です。')
        .email('メールアドレスの形式ではありません。'),
      customerComment: string()
        .required('必須の項目です。'),
    });

    const { errors, meta, handleSubmit, isSubmitting } = useForm({
      validationSchema: schema,
      initialValues: {
        customerName: '',
        customerMail: '',
        customerComment: '',
      },
    });

    const onSubmit = handleSubmit((values, { resetForm }) => {
      console.log(values);
      //入力した値をサーバに送信したりする処理
      resetForm();
    });

    const { value: customerName } = useField('customerName');
    const { value: customerMail } = useField('customerMail');
    const { value: customerComment } = useField('customerComment');

    return {
      customerName,
      customerMail,
      customerComment,
      errors,
      meta,
      onSubmit,
      isSubmitting,
    };
  },



  // 汎用の関数定義
  methods: {

    // フォーム送信処理・フラグ切り替え
    submitAfter: function(){
      this.$refs.contactMailForm.submit()

      console.log({customerName:this.customerName});
      console.log({customerMail:this.customerMail});
      console.log({customerComment:this.customerComment});


      this.formViewFrag = false
      // formの内容をリセット
      this.customerName = ''
      this.customerMail = ''
      this.customerComment = ''
    },

    // confirmModal開閉
    showConfirmModal: function(){
      console.log('show');
      this.confirmModal.show()
    },
    hideConfirmModal: function(){
      console.log('hide');
      this.confirmModal.hide()
      this.formViewFrag = true
    },



  },
  // vueインスタンスがマウントされたタイミングで実行する関数
  mounted: function(){

    // modalをインスタンス化しておく
    this.confirmModal = new bootstrap.Modal(this.$refs.confirmModal)
  },


};
</script>


<style lang='scss' scoped>
  .form-group {
    position: relative;
  }
  div.mfp_ok {
    background: none;
    color: #0CCA00;
    font-size: 14px;
    font-weight: bold;
    position: absolute;
    right: 0;
    top: 0;
    border: none;
  }
  #customerComment {
    width: 100%;
    height: 11em;
  }
  textarea.form-control {
    font-size: 1.4rem;
    line-height: 1.4em;
  }
</style>
