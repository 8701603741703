<template ref="privacy">
  <div class="tBlk">

<!-- Privacy text -->
    <span class="p10h bB">「SCAPP -スクラッチアートのアプリ- スクアプ」を含む、scratchart.app が提供するサービスにおけるプライバシー情報の取扱いについて、以下のとおり定めます。</span><br>

    <br>


    <hr>


    <br><br>

    <h4 class="p10h bB text-center">個人情報保護方針</h4>
    scratchart.appは、以下のとおり個人情報保護方針を定め、個人情報保護の仕組みを構築し、全従業員に個人情報保護の重要性の認識と取組みを徹底させることにより、個人情報の保護を推進致します。

    <br><br><br>

    <h4 class="p10h bB text-center">個人情報の管理</h4>
    scratchart.appは、ユーザーの個人情報を正確かつ最新の状態に保ち、個人情報への不正アクセス・紛失・破損・改ざん・漏洩などを防止するため、セキュリティシステムの維持・管理体制の整備・社員教育の徹底等の必要な措置を講じ、安全対策を実施し個人情報の厳重な管理を行ないます。

    <br><br><br>

    <h4 class="p10h bB text-center">個人情報の利用目的</h4>
    scratchart.appでは、ユーザーからのお問い合わせ時に、お名前、e-mailアドレス、電話番号等の個人情報をご登録いただく場合がございますが、これらの個人情報はご提供いただく際の目的以外では利用いたしません。<br>
    ユーザーからお預かりした個人情報は、当方からのご連絡や業務のご案内やご質問に対する回答として、電子メールや資料のご送付に利用いたします。

    <br><br><br>

    <h4 class="p10h bB text-center">個人情報の第三者への開示・提供の禁止</h4>
    scratchart.appは、ユーザーよりお預かりした個人情報を適切に管理し、次のいずれかに該当する場合を除き、個人情報を第三者に開示いたしません。<br>
    ・ユーザーの同意がある場合<br>
    ・ユーザーが希望されるサービスを行なうために当方が業務を委託する業者に対して開示する場合<br>
    ・法令に基づき開示することが必要である場合

    <br><br><br>

    <h4 class="p10h bB text-center">個人情報の安全対策</h4>
    scratchart.appは、個人情報の正確性及び安全性確保のために、セキュリティに万全の対策を講じています。

    <br><br><br>

    <h4 class="p10h bB text-center">ご本人の照会</h4>
    ユーザーがご本人の個人情報の照会・修正・削除などをご希望される場合には、ご本人であることを確認の上、対応させていただきます。

    <br><br><br>

    <h4 class="p10h bB text-center">法令、規範の遵守と見直し</h4>
    scratchart.appは、保有する個人情報に関して適用される日本の法令、その他規範を遵守するとともに、本ポリシーの内容を適宜見直し、その改善に努めます。

    <br><br><br>

    <h4 class="p10h bB text-center">当サイト・サービスが使用しているアクセス解析ツールについて</h4>
    サイト・サービスの改善を図るため、「Googleアナリティクス」等のアクセス解析ツールを利用する場合がございます。<br>
    アクセス解析ツールは、アクセスに関する情報が匿名で収集される、Cookieを利用することがございます。

    <br><br><br>

    <h4 class="p10h bB text-center">当サイト・サービスに掲載されている広告について</h4>
    当サイト・サービス内において、第三者配信の広告サービスを利用する場合がございます。<br>
    このような広告配信事業者は、ユーザーの興味に応じた商品やサービスの広告を表示するため、当サイト・サービスや他サイトへのアクセスに関する情報が匿名で収集される、Cookieを利用することがございます。

    <br><br><br>

    <h4 class="p10h bB text-center">お問い合せ</h4>
    scratchart.appの個人情報の取扱に関するお問い合せは、下記のフォームよりご連絡くださいませ。

    <br><br><br>

    <p class="text-right fzS">２０１９年４月１日施行</p>

    <br><br>
<!-- Privacy text -->

  </div>
</template>



<script>
export default {
  // コンポーネントをセット
  components: {
  },
  // 各種変数をセット
  data: function(){
    return{
    }
  },
  // 汎用の関数定義
  methods: {
  },
  // vueインスタンスがマウントされたタイミングで実行する関数
  mounted: function(){
  }

};
</script>


<style lang='scss' scoped>
  hr {
    border-color: #bbb;
  }
</style>

